import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';

import { getDealerNames } from './getDealerNames';

export const getInfoPopOverText = (
  strings: Record<string, string>,
  similarVehicles: Vehicle[] | null | undefined,
) => {
  if (similarVehicles?.length) {
    const dealers = getDealerNames(similarVehicles);
    return strings.infoPopoverSimilarCarsDriftrock.replace('{dealers}', dealers);
  }
};

export const getCheckboxLabel = (
  strings: Record<string, string>,
  similarVehicles: Vehicle[] | null | undefined,
) => {
  if (similarVehicles?.length) {
    return strings.oneClickAndDriftRockCheckbox;
  }
};
