import { cache } from 'react';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';

type Props = [uid: string, postcode: string, locale: Locale];

export const fetchSimilarVehiclesOnClient = cache(async (...props: Props) => {
  const [vehicleId, postcode, locale] = props;

  const search = new URLSearchParams({ vehicleId, postcode: postcode ?? undefined });

  if (!vehicleId) return undefined;

  try {
    const data = await fetchJson(`/api/${locale}/similar-vehicles/?${search}`);
    return data as any;
  } catch (error) {
    console.warn('[No similar vehicles found]');
    return null;
  }
});
