import { Locale } from '../Entities/Locale/Locale.entity';
import { fetchJson } from './Fetch/FetchJson';
import { getReCaptchaToken } from './loadGoogleRecaptcha';
import { commonApiURL } from '../Environment/Variables';

type Payload = {
  metaData: {
    trackingId: string;
    vehicle_uuid: string | null;
    leadSource: string;
    gclid?: string;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    cItemId?: string;
    userId: string | undefined | null;
    msclkId?: string;
  };
};

export async function generatePhoneNumber(locale: Locale, payload: Payload) {
  const recaptchaToken = await getReCaptchaToken('lead', locale);

  let ipAddress;
  try {
    ipAddress = await fetchJson(`/api/get-ip`);
  } catch (error) {
    console.error('Error retrieving Client IP address', error);
  }

  const data = await fetchJson<{
    browserSessionId: string;
    displayNumber: string;
    phoneNumber: string;
  }>(`${commonApiURL}/call-leads-gateway/generate_number`, {
    method: 'POST',
    headers: {
      'Recaptcha-Token': recaptchaToken,
      'x-heycar-tenant': locale,
      'x-original-forwarded-for': ipAddress,
    },
    body: JSON.stringify(payload),
  });
  return data;
}
