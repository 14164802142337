import { ReactNode } from 'react';

import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { mapSelectedProductToLeadSubtype } from '@core/Utils/Leads/MapSelectedProductToLeadSubtype';
import { isBusinessHours } from '@core/Utils/isBusinessHours/isBusinessHours';
import { Calculation } from '@fincalc/Entities/Calculation.entity';

import { Call } from '@gds/Icons/Paths/Call';
import { CallBack } from '@gds/Icons/Paths/CallBack';
import { Email } from '@gds/Icons/Paths/Email';
import { AllFlags } from '@growthBookExperimentation/Entities/FlagKeys.entities';
import { Atom } from 'jotai';

import { vehicleHistoryLeadFormSubmitAtom } from 'Atoms/App/Pdp/VehicleHistorySubmit.atom';
import { InputItem } from 'Components/FormFields/FormFields.entity';
import { BookAppointmentFieldItems } from 'Components/LeadForms/BookAppointment/BookAppointmentFieldItems';
import { HistoryCheckItems } from 'Components/LeadForms/HistoryCheck/HistoryCheckItems';
import { HistoryCheckReturnAction } from 'Components/LeadForms/HistoryCheck/HistoryCheckModalReturnAction';
import { StringsType } from 'Components/LeadForms/LeadForm/LeadForm.entity';
import { LeadformMessages } from 'Components/LeadForms/Leadforms.messages';
import { LeasingOnlineModalFieldItems } from 'Components/LeadForms/LeasingOnlineModal/LeasingOnlineModalFieldItems';
import { MessageDealerFieldItems } from 'Components/LeadForms/MessageDealer/MessageDealerFieldItems';
import { MessageDealerFinanceFieldItems } from 'Components/LeadForms/MessageDealer/MessageDealerFinanceFieldItems';
import { RequestCallbackFieldItems } from 'Components/LeadForms/RequestCallback/RequestCallbackFieldItems';
import { RequestImagesFieldItems } from 'Components/LeadForms/RequestImages/RequestImagesFieldItems';
import { RequestVideoFieldItems } from 'Components/LeadForms/RequestVideo/RequestVideoFieldItems';
import { SendDealerMessageAction } from 'Components/LeadForms/SendDealerMessageAction/SendDealerMessageAction';

import { PdPMessagesKeys } from '../Pdp.messages';

import { LeadType } from './LeadCTAs.entity';

export type LeadCtaConfigFunc<T> = (props: {
  flags?: AllFlags;
  isEcom?: boolean;
  vehicle: Vehicle;
  isVsp?: boolean;
  calculation?: Calculation | null;
  isFr?: boolean;
}) => T;

export type LeadCtaRequiredFields = (keyof LeadFormState)[];

export type LeadCtaConfigValue = {
  labelId: PdPMessagesKeys;
  isActive: LeadCtaConfigFunc<boolean>;
  variant: LeadCtaConfigFunc<'outlined' | 'contained' | 'link'>;
  onClick?: LeadCtaConfigFunc<void>;
  fieldItems?: LeadCtaConfigFunc<InputItem[]>;
  successModalHeader?: (strings: LeadformMessages) => string;
  requiredFields?: () => LeadCtaRequiredFields;
  leadSubtype?: (values: LeadFormState, options: { calculation?: Calculation | null }) => string;
  buttonIcon?: ReactNode;
  onErrorModalAction?: (strings: StringsType) => ReactNode;
  onSuccessModalAction?: (strings: StringsType) => ReactNode;
  submitHandlerAtom?: Atom<any>;
  successModalMessages?: (strings: StringsType) => {
    headLine?: string;
    description?: string;
  };
  loadingModalMessages?: (strings: StringsType) => {
    headLine?: string;
  };
  errorModalMessages?: (strings: StringsType) => {
    description?: string;
    headLine?: string;
  };
  showButtonText?: (props: {
    isVsp?: boolean;
    isEcom?: boolean;
    isSticky?: boolean;
    isFr?: boolean;
  }) => boolean | undefined;
};

export type LeadCtaConfig = Record<LeadType, LeadCtaConfigValue>;

export type LeadCtaConfigArray = Array<[LeadType, LeadCtaConfigValue]>;

export const leadCtasConfig = {
  'message-dealer': {
    labelId: 'messageDealerButton',
    isActive: ({ isVsp }) => !isVsp,
    variant: ({ isEcom, isFr }) => (isEcom || isFr ? 'outlined' : 'contained'),
    fieldItems: () => MessageDealerFieldItems,
    successModalHeader: strings => strings.messageDealerButton,
    leadSubtype: values =>
      values.reserveVehicleChkBox
        ? 'reservation'
        : values.preferredTime?.value && values.date
        ? 'request_a_viewing'
        : 'message',
    buttonIcon: <Email />,

    showButtonText: ({ isSticky, isEcom, isFr }) => !isSticky || (isSticky && !isEcom && !isFr),
  },
  'message-dealer-finance': {
    labelId: 'messageDealerButton',
    isActive: () => false,
    variant: ({ isEcom }) => (isEcom ? 'outlined' : 'contained'),
    fieldItems: () => MessageDealerFinanceFieldItems,
    successModalHeader: strings => strings.messageDealerButton,
    leadSubtype: (values, { calculation }) =>
      values.reserveVehicleChkBox
        ? 'reservation'
        : values.preferredTime?.value && values.date
        ? 'request_a_viewing'
        : mapSelectedProductToLeadSubtype(calculation?.selectedProduct),
    buttonIcon: <Email />,
    showButtonText: ({ isSticky, isEcom }) => !isSticky || (isSticky && !isEcom),
  },
  'request-callback': {
    labelId: 'requestCallbackButton',
    isActive: ({ vehicle, flags }) =>
      !isBusinessHours(vehicle?.dealer.openingTimes) || flags?.callbackCtaPublicHoliday,
    variant: ({ isEcom }) => (isEcom ? 'outlined' : 'contained'),
    fieldItems: () => RequestCallbackFieldItems,
    successModalHeader: strings => strings.requestCallbackButton,
    leadSubtype: () => 'callback',
    buttonIcon: <CallBack />,
    showButtonText: ({ isSticky, isVsp, isEcom }) =>
      !isSticky || (isVsp && isSticky) || (isSticky && !isEcom),
  },
  'request-video': {
    labelId: 'requestVideoButton',
    isActive: ({ vehicle, flags }) =>
      !!(
        flags?.pdpRequestVideoCta &&
        !!vehicle?.pricing.package.requestVideo &&
        !vehicle?.videos?.[0]?.link
      ),
    variant: ({ isEcom }) => (isEcom ? 'outlined' : 'contained'),
    fieldItems: () => RequestVideoFieldItems,
    successModalHeader: strings => strings.requestVideo,
    leadSubtype: () => 'video',
    showButtonText: () => true,
  },
  'request-images': {
    labelId: 'requestImagesButton',
    isActive: ({ vehicle, flags }) =>
      !!flags?.pdpRequestImagesCta &&
      (vehicle?.images.length === 0 || vehicle?.images[0].url.includes('coming-soon')),
    variant: ({ isEcom }) => (isEcom ? 'outlined' : 'contained'),
    fieldItems: () => RequestImagesFieldItems,
    successModalHeader: strings => strings.requestImages,
    leadSubtype: () => 'request_an_image',
    showButtonText: () => true,
  },
  'book-appointment': {
    labelId: 'bookAppointmentButton',
    isActive: ({ flags, vehicle }) =>
      !!flags?.leadEnrichmentBookAppointment && !!vehicle?.pricing.package.bookAppointment,
    variant: ({ isEcom }) => (isEcom ? 'outlined' : 'contained'),
    fieldItems: () => BookAppointmentFieldItems,
    successModalHeader: strings => strings.bookAppointmentAccordionHeader,
    leadSubtype: () => 'request_a_viewing',
    showButtonText: () => true,
  },
  'leasing-online': {
    labelId: 'leasingOnlineButton',
    isActive: () => false,
    variant: ({ isEcom }) => (isEcom ? 'outlined' : 'contained'),
    fieldItems: () => LeasingOnlineModalFieldItems,
    successModalHeader: () => 'Message us',
    leadSubtype: () => 'leasing_online',
    showButtonText: () => true,
  },
  'call-dealer': {
    labelId: 'callDealerButton',
    isActive: ({ vehicle, flags }) =>
      !flags?.callbackCtaPublicHoliday && isBusinessHours(vehicle?.dealer?.openingTimes),
    variant: ({ isEcom, isFr }) => (isFr && !isEcom ? 'contained' : 'outlined'),
    onClick: () => alert('Call dealer not implemented yet'),
    leadSubtype: () => 'call_dealer',
    successModalHeader: () => 'Call dealer not implemented yet',
    showButtonText: ({ isSticky, isVsp, isEcom }) =>
      !isSticky || (isVsp && isSticky) || (isSticky && !isEcom),
    buttonIcon: <Call />,
  },
  'history-check': {
    submitHandlerAtom: vehicleHistoryLeadFormSubmitAtom,
    labelId: 'historyCheckButton',
    isActive: () => true,
    variant: () => 'outlined',
    onClick: () => alert('HOV not implemented yet'),
    leadSubtype: () => 'history_check',
    fieldItems: () => HistoryCheckItems,
    requiredFields: () => ['email'],
    onSuccessModalAction: strings => (
      <>
        <HistoryCheckReturnAction strings={strings} />
      </>
    ),
    onErrorModalAction: strings => <SendDealerMessageAction label={strings.messageDealerButton} />,
    successModalHeader: strings => strings.historyCheckModalHeader,
    successModalMessages: strings => ({
      description: strings.successHistoryCheckModalDescription,
    }),
    errorModalMessages: strings => ({
      headLine: strings.errorHistoryCheckModalHeadLine,
      description: strings.errorHistoryCheckModaldescription,
    }),
    loadingModalMessages: strings => ({
      headLine: strings.loadingHistoryCheckModalDescription,
    }),
  },
} as const satisfies LeadCtaConfig;
