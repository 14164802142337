import { ukApiURL } from '@core/Environment/Variables';

import { OptInUrls } from './OptIn.entity';

export const OPT_IN_URLS: OptInUrls = {
  uk: `${ukApiURL}/opt-in/`,
};
export const optInKeys = {
  uk: 'hc_marketing_opt_in',
  fr: 'hc_marketing_opt_in_fr',
};
