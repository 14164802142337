import { useEffect, useState } from 'react';

import { getClientUserId } from '@cookies/GetClientUserId';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { tenantAtom } from '@core/Atoms/Tenant/Tenant.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { currentVehicleIdAtom } from '@core/Atoms/Vehicle/CurrentVehicleId.atom';
import { useModal } from '@core/Components/Modal/UseModal';
import { isWindowDefined } from '@core/Constants/Window';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import {
  createEventWithCallNumber,
  generateLeadTrackingData,
} from '@core/Tracking/LeadForms.tracking';
import { generatePhoneNumber } from '@core/Utils/GetPhoneNumber';
import { loadGoogleRecaptcha } from '@core/Utils/loadGoogleRecaptcha';
import Button from '@gds/Button/Button';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { dealerNumberAtom } from 'Atoms/App/Pdp/DealerNumber.atom';
import { dealerNumberErrorAtom } from 'Atoms/App/Pdp/DealerNumberError.atom';
import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';
import { showDealerNumberPdpAtom } from 'Atoms/App/Pdp/ShowDealerNumberPdp.atom';
import { userIdAtom } from 'Atoms/User/UserId.atom';
import { useInitialMount } from 'Hooks/UseInitialMount';

import { CallBackModal } from '../CallBackModal/CallBackModal';
import { LeadForm } from '../LeadForm/LeadForm';
import { StringsType } from '../LeadForm/LeadForm.entity';
import { StatusDisplay } from '../StatusDisplay/StatusDisplay';

import { leadModalHeaderText } from '../Utils/Leadforms.util';

import { callDealerCtasConfig, CtaPlacement } from './CallDealer.config';

import styles from './CallDealerForm.module.css';

import leadFormStyles from '../LeadForm/LeadForm.module.css';

export interface Props {
  strings: StringsType;
  locale: Locale;
  isSticky: boolean;
  isVsp?: boolean;
  isEcom: boolean;
  hasReserveNow?: boolean;
  ctaPlacement?: CtaPlacement;
}

export const CallDealerCTA = ({
  ctaPlacement = 'pdp_call-dealer',
  strings,
  locale,
  isSticky,
  isVsp,
  isEcom,
  hasReserveNow,
}: Props) => {
  const isInitialMount = useInitialMount();
  const vehicleId = useAtomValue(currentVehicleIdAtom);
  const vehicle = useAtomValue(currentVehicleAtom)!;
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const [isNumberVisible, setIsNumberVisible] = useAtom(showDealerNumberPdpAtom);
  const [dealerNumber, setDealerNumber] = useAtom(dealerNumberAtom);
  const [dealerNumberError, setDealerNumberError] = useAtom(dealerNumberErrorAtom);
  const setLeadFormId = useSetAtom(leadFormIdAtom);
  const tenant = useAtomValue(tenantAtom);
  const [phoneNumber, setPhoneNumber] = useState({
    isLoading: false,
    number: '',
    isError: false,
  });
  const { openModal } = useModal();

  const isInvestorStockLayout = locale === 'uk' && tenant !== 'rac' && vehicle?.spec?.d2c && isEcom;
  const ctaConfig = callDealerCtasConfig[ctaPlacement];

  useEffect(() => {
    if (!isInitialMount) {
      const { isError, number, isLoading } = phoneNumber;

      if (isError) {
        setDealerNumberError(true);

        if (ctaConfig.shouldShowRequestCallbackModal(window.innerWidth, isSticky)) {
          openModal(
            <CallBackModal handleCallbackClick={handleCallbackClick} messages={strings} />,
            {
              header: strings[ctaConfig.labelId],
              modalClassName: styles.modalContainer,
            },
          );
        }
      } else {
        if (number && !isLoading) {
          const query = window.matchMedia(`(max-width: 767px)`);
          if (query.matches) {
            window.open(`tel:${number}`, '_self');
          }
        }
      }
    }
  }, [phoneNumber]);

  const { callStart, callSubmit } = generateLeadTrackingData({
    searchParams,
    userId,
    formState,
    leadId: '',
    listingId: vehicleId,
    vehicle,
    leadFormId: 'call-dealer',
    ctaLocation: 'pdp',
  });

  const handleClick = async () => {
    if (isNumberVisible) {
      const callSubmitEvent = createEventWithCallNumber(vehicleId!, phoneNumber.number, 'pdp');
      trackCustomEvent({ event: callSubmitEvent!, context: callSubmit.context });
      window.open(`tel:${phoneNumber.number}`, '_self');
      return;
    }

    trackCustomEvent({ event: callStart.event!, context: callStart.context });
    setPhoneNumber({
      ...phoneNumber,
      isLoading: true,
    });

    const payload = {
      metaData: {
        trackingId: getClientUserId(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        vehicle_uuid: vehicleId,
        leadSource: tenant || 'web',
        gclid: searchParams?.gclid,
        utmSource: searchParams?.utm_source,
        utmMedium: searchParams?.utm_medium,
        utmCampaign: searchParams?.utm_campaign,
        cItemId: searchParams?.cItemId,
        userId: userId,
        msclkId: searchParams?.msclkid,
      },
    };

    try {
      const result = await loadGoogleRecaptcha(locale);

      if (result) {
        try {
          const data = await generatePhoneNumber(locale, payload);

          if (window.innerWidth < 768) {
            const callSubmitEvent = createEventWithCallNumber(
              vehicleId!,
              phoneNumber.number,
              'pdp',
            );
            trackCustomEvent({ event: callSubmitEvent!, context: callSubmit.context });
          }
          setPhoneNumber({
            ...phoneNumber,
            number: data?.displayNumber,
            isLoading: false,
          });
          setDealerNumber(data?.displayNumber);
          if (window.innerWidth > 768) {
            setIsNumberVisible(true);
          }
        } catch (error) {
          setPhoneNumber({
            ...phoneNumber,
            isLoading: false,
            isError: true,
          });
        }
      }
    } catch (error) {
      // Displays error if there is some issue with Captcha script loading

      openModal(
        <StatusDisplay
          messages={{
            description: strings.leadFormError,
          }}
          type="error"
        />,
        {
          header: strings[ctaConfig.labelId],
          modalClassName: styles.modalContainer,
        },
      );
    }
  };

  const handleCallbackClick = () => {
    setLeadFormId('request-callback');
    const {
      leadStart: { event, context },
    } = generateLeadTrackingData({
      searchParams,
      userId,
      formState,
      leadFormId: 'request-callback',
      leadId: '',
      listingId: vehicleId,
      vehicle,
      ctaLocation: 'pdp',
      leadAction: '',
    });
    trackCustomEvent({ event, context });
    openModal(<LeadForm strings={strings} locale={locale} plp />, {
      header: leadModalHeaderText('request-callback', strings),
      modalClassName: leadFormStyles.modalContainer,
      modalBodyClassName: leadFormStyles.modalBodyClassName,
    });
  };
  const openingTimes = vehicle?.dealer?.openingTimes || [];
  const shouldShowCta = ctaConfig.shouldShowCta({ openingTimes }) as boolean;

  const hasButtonText = ctaConfig.showButtonText({ isSticky, isVsp, isEcom });

  const ctaText = () => {
    if (hasReserveNow && isSticky && isWindowDefined && window.innerWidth < 768) {
      return '';
    }

    if (isWindowDefined && window.innerWidth > 768) {
      if (isNumberVisible && !isInvestorStockLayout) {
        return dealerNumber;
      }
      if (isInvestorStockLayout) {
        return strings.call;
      }
    }

    if (hasButtonText) {
      return isInvestorStockLayout ? strings.call : strings[ctaConfig.labelId];
    }

    return '';
  };

  if (!shouldShowCta) return null;

  const isFr = locale === 'fr';

  if (ctaConfig.requestCallback(dealerNumberError, openingTimes)) {
    return (
      <>
        {ctaConfig.shouldShowPhoneNumberError(dealerNumberError, isSticky) && (
          <div className={styles.error}>{strings.callDealerError}</div>
        )}
        <Button
          leftIcon={ctaConfig.requestCallbackLeftIcon(isSticky, isEcom, hasReserveNow, isVsp)}
          variant={`${locale === 'fr' && !isEcom ? 'contained' : 'outlined'}`}
          onClick={handleCallbackClick}
          className={`${locale === 'fr' && isSticky ? styles.topPriority : ''}`}
          loading={false}
        >
          {ctaConfig.requestCallbackButtonLabel(strings, isSticky, isEcom, hasReserveNow)}
        </Button>
      </>
    );
  }

  if (!dealerNumberError)
    return (
      <>
        <Button
          leftIcon={
            (isSticky && isEcom && !isVsp && !phoneNumber.isLoading) ||
            isNumberVisible ||
            (hasReserveNow && isSticky)
              ? ctaConfig.buttonIcon
              : null
          }
          dataTestId="callDealerButton"
          variant={ctaConfig.variant({ isEcom, isFr })}
          loading={phoneNumber.isLoading}
          onClick={handleClick}
          className={`${locale === 'fr' && isSticky ? styles.topPriority : ''}`}
        >
          {ctaText()}
        </Button>
      </>
    );
};
