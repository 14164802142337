import { DEFAULT_LOCALE, FR_LOCALE } from '@core/Constants/Locale.constants';
import { atomWithStorage } from 'jotai/utils';

export type PostcodeAtomType = {
  [key: string]: string;
};

export const postcodeAtom = atomWithStorage<PostcodeAtomType>('postcodeValue', {
  [DEFAULT_LOCALE]: '',
  [FR_LOCALE]: '',
});
