import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { LOCALE } from '@core/Constants/Locale.constants';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import Checkbox from '@gds/Checkbox/Checkbox';
import { Info } from '@gds/Icons/Paths/Info';
import { Typography } from '@gds/Typography/Typography';
import { useAtomValue } from 'jotai';

import { similarVehiclesAtom } from 'Atoms/App/Pdp/SimilarVehicles.atom';
import { Divider } from 'Components/Divider/Divider';
import { Popover } from 'Components/Popover/Popover';

import { getCheckboxLabel, getInfoPopOverText } from '../Utils/getOneClickChkboxData';

import { PrivacyPolicyLink } from './PrivacyPolicyLink/PrivacyPolicyLink';

import styles from '../OneClickCheckbox/OneClickCheckbox.module.css';
interface Props {
  strings: Record<string, string>;
  isOneClickDriftrockChecked: boolean;
  inputProps: {
    id: string;
    handleChange: (field: string, value: boolean) => void;
  };
}

export const ONECLICK_TERMS_LINK = {
  uk: '/uk/multi-submit-terms',
  fr: '/fr/multi-submit-terms',
};

export const OneClickDriftrockCheckbox = ({
  strings,
  inputProps,
  isOneClickDriftrockChecked,
}: Props) => {
  const similarVehicles = useAtomValue(similarVehiclesAtom);

  // Return early if there are no similar vehicles
  if (!similarVehicles?.length) return null;

  const { id: oneClickDriftrockChkBoxId, handleChange } = inputProps;
  const locale: Locale = getClientSideCookieValue(LOCALE);
  const { similarCarsHeader, oneClickTerms } = strings;

  const infoPopOverText = getInfoPopOverText(strings, similarVehicles);
  const checkboxLabel = getCheckboxLabel(strings, similarVehicles);
  return (
    <>
      <div className={styles.similarCarsHeader}>
        <Typography variant="h6">{similarCarsHeader} </Typography>
        <Popover text={infoPopOverText} extraText={<PrivacyPolicyLink />}>
          <Info fontSize={24} />
        </Popover>
      </div>
      <label
        htmlFor="one-click-driftrock-similarcars"
        aria-labelledby="one-click-driftrock-similarcars"
        className={styles.oneClickCheckbox}
      >
        <Checkbox
          id="one-click-driftrock-similarcars"
          checked={isOneClickDriftrockChecked}
          onChange={() => null}
          onClick={() => handleChange(oneClickDriftrockChkBoxId, !isOneClickDriftrockChecked)}
        />
        <Typography variant="body3" Component="span">
          {checkboxLabel}{' '}
          <Typography
            variant="buttonLink1"
            Component="a"
            className={styles.termHref}
            // @ts-expect-error - href
            href={ONECLICK_TERMS_LINK[locale]}
            target="_blank"
          >
            {oneClickTerms}
          </Typography>
        </Typography>
      </label>
      <Divider />
    </>
  );
};
