import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { LOCALE } from '@core/Constants/Locale.constants';
import { FR_PHONE_VALIDATION, UK_PHONE_VALIDATION } from '@core/Constants/Validations';

export const checkMinDate = (value: string) => {
  const dateValue = Number.isNaN(Date.parse(value))
    ? value
    : new Date(value).toISOString?.().split('T')[0];

  const minDateValue = new Date().toISOString?.().split('T')[0];
  const date = new Date(dateValue).getTime();
  const min = new Date(minDateValue).getTime();

  return date >= min;
};

export const leadModalHeaderText = (leadType: string, messages: Record<string, string>): string => {
  const {
    messageDealerButton,
    requestCallbackButton,
    requestVideoButton,
    requestImagesButton,
    bookAppointmentButton,
    leasingOnlineButtonHeader,
  } = messages;
  const headerText: Record<string, string> = {
    'message-dealer': messageDealerButton,
    'message-dealer-finance': messageDealerButton,
    'request-callback': requestCallbackButton,
    'request-video': requestVideoButton,
    'request-images': requestImagesButton,
    'book-appointment': bookAppointmentButton,
    'leasing-online': leasingOnlineButtonHeader,
  };
  return headerText[leadType];
};

export const isValidPhoneNumber = (value: string): boolean => {
  const locale = getClientSideCookieValue(LOCALE);
  return locale === 'uk' ? UK_PHONE_VALIDATION.test(value) : FR_PHONE_VALIDATION.test(value);
};
