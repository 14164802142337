import {
  FR_NUMBER_PLATE_REGEX,
  NUMBER_PLATE_REGEX,
  UK_POSTCODE_REGEX,
} from '@core/Constants/Validations';
import { validateFullName } from '@core/Utils/Validation/ValidateFullName';

import { InputItem } from 'Components/FormFields/FormFields.entity';

import { defaultErrorMessage, fullNameErrorMessage } from '../Utils/ErrorMessages';
import { checkMinDate, isValidPhoneNumber } from '../Utils/Leadforms.util';

export const RequestCallbackFieldItems: InputItem[] = [
  {
    id: 'firstName',
    dataTestId: 'text',
    type: 'input',
    autoComplete: 'on',
    label: ({ firstNameLabel }) => firstNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: ({ flags }) => !flags.nameFieldLeadformExperiment,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'lastName',
    dataTestId: 'text',
    type: 'input',
    autoComplete: 'on',
    label: ({ lastNameLabel }) => lastNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: ({ flags }) => !flags.nameFieldLeadformExperiment,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'fullName',
    dataTestId: 'fullName',
    type: 'input',
    autoComplete: 'on',
    label: ({ fullNameLabel }) => fullNameLabel,
    isValid: ({ value }) => validateFullName(value),
    isActive: ({ flags }) => flags.nameFieldLeadformExperiment,
    width: () => 'full',
    errorMessage: fullNameErrorMessage,
  },
  {
    id: 'phone',
    dataTestId: 'text',
    type: 'input',
    label: ({ phoneNumberLabel }) => phoneNumberLabel,
    isValid: ({ value }) => isValidPhoneNumber(value),
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'postcode',
    dataTestId: 'text',
    type: 'input',
    label: ({ postalCodeLabel }) => postalCodeLabel,
    isValid: ({ value }) => UK_POSTCODE_REGEX.test(value),
    isActive: ({ flags }) => !!flags.postcodeRequired,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'date',
    type: 'date',
    label: ({ bookAppointmentDate }) => bookAppointmentDate,
    isValid: ({ value }) => checkMinDate(value),
    errorMessage: defaultErrorMessage,
    isActive: () => true,
  },
  {
    type: 'select',
    id: 'preferredTime',
    label: ({ bookAppointmentPreferredTime }) => bookAppointmentPreferredTime,
    isActive: () => true,
    options: strings => [
      { value: 'morning', label: strings?.bookAppointmentMorning },
      { value: 'afternoon', label: strings?.bookAppointmentAfternoon },
    ],
  },
  {
    id: 'message',
    dataTestId: 'text',
    type: 'textarea',
    label: ({ optionalMessageLabel }) => optionalMessageLabel,
    width: () => 'full',
    isActive: () => true,
  },

  {
    id: 'valuationChkBox',
    defaultValue: () => true,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange &&
      !!vehicle.pricing.package.messagePartX &&
      !!vehicle.pricing.package.messageValuation,
  },
  {
    id: 'registration',
    errorMessage: defaultErrorMessage,
    isValid: ({ value }) =>
      // Returning true if value is empty [optional field]
      value === '' ? true : NUMBER_PLATE_REGEX.test(value) || FR_NUMBER_PLATE_REGEX.test(value),
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange && !!vehicle.pricing.package.messagePartX,
  },
  {
    id: 'mileage',
    errorMessage: defaultErrorMessage,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange && !!vehicle.pricing.package.messagePartX,
  },
  {
    id: 'oneClickChkBox',
    defaultValue: () => false,
    isActive: ({ flags }) => flags.leadFormOneClickChkbox,
  },
  {
    id: 'financeQuoteChkBox',
    defaultValue: ({ locale }) => (locale === 'uk' ? true : false),
    isActive: ({ vehicle, flags, isFinCalcInteractedWith }) =>
      (flags.leadEnrichmentFinanceQuote && !!vehicle?.pricing.package.messageFinance) ||
      isFinCalcInteractedWith,
  },
  {
    id: 'financeQuoteTcsChkBox',
    type: 'checkbox',
    defaultValue: ({ isFinCalcInteractedWith }) => !isFinCalcInteractedWith,
    isActive: ({ vehicle, flags, isFinCalcInteractedWith, locale }) =>
      locale === 'fr' &&
      ((flags.leadEnrichmentFinanceQuote && !!vehicle?.pricing.package.messageFinance) ||
        isFinCalcInteractedWith),
    isValid: ({ values }) => {
      return (
        (values['financeQuoteChkBox'] && values['financeQuoteTcsChkBox']) ||
        !values['financeQuoteChkBox']
      );
    },
    errorMessage: defaultErrorMessage,
  },
];
