import { Call } from '@gds/Icons/Paths/Call';
import { Typography } from '@gds/Typography/Typography';

import styles from '../CallDealer.module.css';

export const CallBack = (strings: Record<string, any>) => {
  return (
    <div className={styles.wrapper}>
      <Call />
      <Typography Component="span" variant="subheading3">
        {strings.strings.callBack}
      </Typography>
    </div>
  );
};
