import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { LOCALE } from '@core/Constants/Locale.constants';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import Checkbox from '@gds/Checkbox/Checkbox';
import { Info } from '@gds/Icons/Paths/Info';
import { Typography } from '@gds/Typography/Typography';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { useAtomValue } from 'jotai';

import { similarVehiclesAtom } from 'Atoms/App/Pdp/SimilarVehicles.atom';
import { Divider } from 'Components/Divider/Divider';
import { Popover } from 'Components/Popover/Popover';

import { getDealerNames } from '../Utils/getDealerNames';

import styles from './OneClickCheckbox.module.css';
interface Props {
  strings: Record<string, string>;
  isOneClickChecked: boolean;
  inputProps: {
    id: string;
    handleChange: (field: string, value: boolean) => void;
  };
}

export const ONECLICK_TERMS_LINK = {
  uk: '/uk/multi-submit-terms',
  fr: '/fr/multi-submit-terms', // will change later
};

export const OneClickCheckbox = ({ strings, inputProps, isOneClickChecked }: Props) => {
  const similarVehicles = useAtomValue(similarVehiclesAtom);
  const { id: oneClickChkBoxId, handleChange } = inputProps;
  const locale: Locale = getClientSideCookieValue(LOCALE);
  const { leadFormOneClickChkbox: hasOneClick } = useAtomValue(flagsAtom);
  const { oneClickCheckbox, similarCarsHeader, infoPopoverSimilarCars, oneClickTerms } = strings;

  const isMultiLead = hasOneClick && !!similarVehicles?.length;

  if (!isMultiLead) return null;
  const dealers = getDealerNames(similarVehicles);

  // temporary solution for dynamic phrase string
  const infoPopOverText = infoPopoverSimilarCars.replace('{dealers}', dealers);

  return (
    <>
      <div className={styles.similarCarsHeader}>
        <Typography variant="h6">{similarCarsHeader} </Typography>
        <Popover text={infoPopOverText}>
          <Info fontSize={24} />
        </Popover>
      </div>
      <label
        htmlFor="one-click-similarcars"
        aria-labelledby="one-click-similarcars"
        className={styles.oneClickCheckbox}
      >
        <Checkbox
          id="one-click-similarcars"
          checked={isOneClickChecked}
          onChange={() => null}
          onClick={() => handleChange(oneClickChkBoxId, !isOneClickChecked)}
        />
        <Typography variant="body3" Component="span">
          {oneClickCheckbox}{' '}
          <Typography
            variant="buttonLink1"
            Component="a"
            className={styles.termHref}
            // @ts-expect-error - href
            href={ONECLICK_TERMS_LINK[locale]}
            target="_blank"
          >
            {oneClickTerms}
          </Typography>
        </Typography>
      </label>
      <Divider />
    </>
  );
};
