import { asMessages } from '@core/Utils/Intl/AsMessages';

const definition = asMessages(
  'containers.pdp',
  {
    buyOnline: 'Buy online',
    buy: 'Buy',
    enquireOnline: 'Enquire',
    pageTitle: `{stockCondition} {make} {model} for sale - {variant}`,
    pageDescription: `{stockCondition} {year} {prettyNameLong} for sale for {price}. Sold by {dealer} in {town}, {country}.`,
    messageDealerButton: 'Message dealer',
    requestCallbackButton: 'Request callback',
    requestVideoButton: 'Request video',
    requestImagesButton: 'Request images',
    bookAppointmentButton: 'Book appointment',
    leasingOnlineButton: 'Message us',
    leasingOnlineBody:
      'Enter your details below & one of our team will be in touch to talk through your leasing options.',
    leasingOnlineButtonHeader: 'Message us',
    reservedVehicleLabel: 'Vehicle reserved',
    inActiveVehicleLabel: 'Vehicle unavailable',
    callDealerButton: 'Call dealer',
    historyCheckButton: 'Receive the report',
    buyOnlineWith: 'powered by',
    viewReport: 'View report',
    leadsReCaptcha:
      'This site is protected by reCAPTCHA and the Google <privacyPolicy>{privacyPolicyLabel} </privacyPolicy> and <termsOfService>{label}</termsOfService> apply.',
    homeDelivery: 'Home delivery',
    buyOnlineHeader: 'Buying a car online with heycar',
    financeCalculatorTitle: 'Finance calculator',
    pwoweredBy: 'Powered by {heycar} and provided by the {dealer}',
    callDealerError:
      'Sorry, an error occurred when retrieving the phone number. Please request a call back instead.',
    reserveNow: 'Reserve now',
    reserve: 'Reserve',
    message: 'Message',
    call: 'Call',
    hurryUpPillMobileText: 'Hurry up!', // max characters 35
    hurryUpPillText: 'Hurry up! This car will be taken off soon',
    leasingText: 'Leasing',
    filteredPlpBrdcmbText: 'Back to search',
    unFilteredPlpBrdcmbText: 'See all cars',
    makeModelPlpBrdcmbText: 'View all {make} {model}',
    messageUs: 'Message us',
    leasingUrl: 'https://share.hsforms.com/1tCDYYr7gSyW01Ubk7NuV0g3n1ib',
    leasingCallUs: '020 3936 1257',
    leasingCallText: 'Call us',
    investorStockTerms:
      '*£250 Insurance contribution offer is only available to customers ordering an eligible vehicle via heycar (heycar.com) or buyacar (buyacar.co.uk) between 5th August 2024 and 31st December 2024. Contribution is valid for 3 months after vehicle handover date. The contribution will be applied automatically when getting a new quote, or for existing Audi, SEAT, Škoda and Volkswagen Car Insurance customers it will be applied to their next renewal premium. This contribution offer can only be redeemed once. Cover is subject to meeting the eligibility criteria for an existing Audi, SEAT, Škoda and Volkswagen Car Insurance policy, underwritten by U K Insurance Limited. Excludes Telematics products. For full terms and conditions relating to this contribution offer, please visit the relevant brand website: {audiContribution}, {seatContribution}, {skodaContribution}, {vwContribution}{br}{br}Audi, SEAT, Škoda and Volkswagen Car Insurance is provided and underwritten by U K Insurance Limited. Calls may be recorded. Volkswagen Financial Services (UK) Limited (“VWFS UK”), registered in England and Wales No.2835230 (registered office: Brunswick Court, Yeomans Drive, Blakelands, Milton Keynes MK14 5LR). VWFS UK is authorised and regulated by the Financial Conduct Authority. VWFS UK and U K Insurance Limited are not part of the same corporate group.',
  } as const,
  {
    heycar: <strong>heycar</strong>,
    dealer: <b>dealer</b>,
  },
);

export const pdpMessages = definition.messages;
export const defaultPdpMessages = definition.defaultMessages;
export type PdpMessages = typeof definition.messages;
export type PdPMessagesKeys = keyof PdpMessages;
