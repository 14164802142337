import {
  EMAIL_VALIDATION,
  FR_NUMBER_PLATE_REGEX,
  NUMBER_PLATE_REGEX,
  UK_POSTCODE_REGEX,
} from '@core/Constants/Validations';

import { InputItem } from 'Components/FormFields/FormFields.entity';

import { defaultErrorMessage } from '../Utils/ErrorMessages';
import { checkMinDate, isValidPhoneNumber } from '../Utils/Leadforms.util';

const BookAppointmentFieldItems: InputItem[] = [
  {
    id: 'firstName',
    dataTestId: 'firstName',
    type: 'input',
    autoComplete: 'on',
    label: ({ firstNameLabel }) => firstNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'lastName',
    dataTestId: 'lastName',
    type: 'input',
    autoComplete: 'on',
    label: ({ lastNameLabel }) => lastNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'email',
    dataTestId: 'email',
    type: 'input',
    label: ({ emailAddressLabel }) => emailAddressLabel,
    isValid: ({ value }) => EMAIL_VALIDATION.test(value),
    isActive: () => true,
    width: ({ flags }) => (flags.postcodeRequired ? 'full' : 'half'),
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'phone',
    dataTestId: 'phone',
    type: 'input',
    label: ({ phoneNumberLabel }) => phoneNumberLabel,
    isValid: ({ value }) => isValidPhoneNumber(value),
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'postcode',
    dataTestId: 'postcode',
    type: 'input',
    label: ({ postalCodeLabel }) => postalCodeLabel,
    isValid: ({ value }) => UK_POSTCODE_REGEX.test(value),
    isActive: ({ flags }) => !!flags.postcodeRequired,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
    defaultValue: ({ extraData }) => extraData?.postcode,
  },
  {
    id: 'date',
    type: 'date',
    label: ({ bookAppointmentDate }) => bookAppointmentDate,
    isValid: ({ value }) => checkMinDate(value),
    errorMessage: defaultErrorMessage,
    isActive: () => true,
  },
  {
    type: 'select',
    id: 'preferredTime',
    label: ({ bookAppointmentPreferredTime }) => bookAppointmentPreferredTime,
    isActive: () => true,
    options: strings => [
      { value: 'morning', label: strings?.bookAppointmentMorning },
      { value: 'afternoon', label: strings?.bookAppointmentAfternoon },
    ],
  },
  {
    id: 'message',
    dataTestId: 'message',
    type: 'textarea',
    label: ({ optionalMessageLabel }) => optionalMessageLabel,
    width: () => 'full',
    isActive: () => true,
  },
  {
    id: 'valuationChkBox',
    defaultValue: () => true,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange &&
      !!vehicle.pricing.package.messagePartX &&
      !!vehicle.pricing.package.messageValuation,
  },
  {
    id: 'registration',
    errorMessage: defaultErrorMessage,
    isValid: ({ value }) =>
      // Returning true if value is empty [optional field]
      value === '' ? true : NUMBER_PLATE_REGEX.test(value) || FR_NUMBER_PLATE_REGEX.test(value),
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange && !!vehicle.pricing.package.messagePartX,
  },
  {
    id: 'mileage',
    errorMessage: defaultErrorMessage,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange && !!vehicle.pricing.package.messagePartX,
  },
  {
    id: 'financeQuoteChkBox',
    defaultValue: ({ locale }) => (locale === 'uk' ? true : false),
    isActive: ({ vehicle, flags, isFinCalcInteractedWith }) =>
      (flags.leadEnrichmentFinanceQuote && !!vehicle.pricing.package.messageFinance) ||
      isFinCalcInteractedWith,
  },
  {
    id: 'financeQuoteTcsChkBox',
    type: 'checkbox',
    defaultValue: ({ isFinCalcInteractedWith }) => !isFinCalcInteractedWith,
    isActive: ({ vehicle, flags, isFinCalcInteractedWith, locale }) =>
      locale === 'fr' &&
      ((flags.leadEnrichmentFinanceQuote && !!vehicle.pricing.package.messageFinance) ||
        isFinCalcInteractedWith),
    isValid: ({ values }) => {
      return values['financeQuoteChkBox'] && values['financeQuoteTcsChkBox'];
    },
    errorMessage: defaultErrorMessage,
  },
];

export { BookAppointmentFieldItems };
