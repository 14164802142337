import React, { useState } from 'react';

import Button from '@gds/Button/Button';
import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';

import styles from './Popover.module.css';
interface Props {
  children: React.ReactNode;
  text: string | React.ReactNode;
  popoverId?: string;
  extraText?: React.ReactNode;
}

export const Popover = ({ children, text, popoverId = 'popover-content', extraText }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div onMouseEnter={() => setIsOpen(true)} className={styles.popOverWrapper}>
      <Button
        aria-controls={popoverId}
        aria-expanded={isOpen}
        aria-haspopup="dialog"
        className={styles.infoIcon}
        color="primary"
        onClick={() => setIsOpen(!isOpen)}
        size="large"
        type="button"
      >
        {children}
      </Button>
      {isOpen && (
        <div
          className={`${styles.popOverContent} ${extraText ? styles.extraText : ''}`}
          id={popoverId}
          onMouseLeave={() => setIsOpen(false)}
          role="dialog"
        >
          <Button
            aria-label="Close"
            className={styles.closeIcon}
            leftIcon={<Close />}
            onClick={() => setIsOpen(!isOpen)}
            type="button"
          />
          <Typography variant="subheading3">{text}</Typography>
          {extraText}
        </div>
      )}
    </div>
  );
};
