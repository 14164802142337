'use client';

import { closeModalAtom, openModalAtom } from '@core/Atoms/Modal/Modal.atom';
import { ModalProps } from '@core/Entities/Modal/Modal.entity';
import { atom } from 'jotai';

import { ReturnAction } from 'Components/LeadForms/ReturnAction/ReturnAction';
import { StatusDisplay } from 'Components/LeadForms/StatusDisplay/StatusDisplay';
import { leadCtasConfig } from 'Components/Pdp/LeadCTAs/LeadCtaConfig';

import { leadFormIdAtom } from './LeadFormId.atom';
import { leadFormStringsAtom } from './LeadFormStrings.atom';

import statusDisplayStyles from 'Components/LeadForms/StatusDisplay/StatusDisplay.module.css';

export const openSuccessModalAtom = atom(null, (get, set, modalProps?: ModalProps) => {
  const strings = get(leadFormStringsAtom);
  const pathname = window.location.pathname;
  const backButtonMessage = pathname.endsWith('/autos')
    ? strings.successModalBackButton
    : window.history.length <= 2
    ? strings.successModalSimilarCars
    : strings.successModalBackButton;

  const leadFormId = get(leadFormIdAtom);
  const preApprovalStartButton = strings.preApprovalStartButton;
  const thankYouCloseButton = strings.thankYouCloseButton;
  const preApprovalTitle = strings.preApprovalTitle;
  const preApprovalSubTitle = strings.preApprovalSubTitle;
  const financePreApprovalError = strings.financePreApprovalError;
  const isLeasingLead = leadFormId === 'leasing-online';

  // @ts-expect-error - onErrorModalAction is optional
  const successModalAction = leadCtasConfig[leadFormId]?.onSuccessModalAction?.(strings);
  // @ts-expect-error - successModalMessages is optional
  const messages = leadCtasConfig[leadFormId].successModalMessages?.(strings);
  const description = isLeasingLead
    ? strings.successLeasingDescription
    : messages?.description ?? strings.successModalMessageDescription;
  set(closeModalAtom);
  set(
    openModalAtom,
    <StatusDisplay
      messages={{
        description,
        headline: messages?.headLine ?? strings.successModalMessageHeadline,
      }}
      type="success"
      kwankoTrackingId={modalProps?.kwankoTrackingId}
      kwankoListingId={modalProps?.kwankoListingId}
    />,
    {
      header: strings.messageDealerButton,
      modalAction: successModalAction ? (
        successModalAction
      ) : (
        <ReturnAction
          messages={{
            preApprovalStartButton,
            thankYouCloseButton,
            preApprovalTitle,
            preApprovalSubTitle,
            financePreApprovalError,
            backButtonMessage,
          }}
        />
      ),
      modalClassName: statusDisplayStyles.statusDisplayWrapper,
      footerClassName: statusDisplayStyles.returnActionCta,
      modalContentClassName: statusDisplayStyles.statusDisplayBodyWrapper,
      ...modalProps,
    },
  );
});
